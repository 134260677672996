var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{staticClass:"document-table document-table_sticky",attrs:{"id":"doc-table-headers"},nativeOn:{"scroll":function($event){return _vm.onScrollCb($event)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{key:header.value,staticClass:"document-table__th pl-3 pr-3 pt-2 pb-2",style:({ width: header.width ? ((header.width) + "px") : '' })},[_c('v-tooltip',{attrs:{"fixed":"","z-index":"200","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('div',[_vm._v(_vm._s(header.tooltip))])])],1)}),0)]),(!_vm.resultData.length)?_c('tbody',[_c('tr',[_c('td',{staticClass:"document-table__no-data",attrs:{"colspan":_vm.headers.length}},[_vm._v("Добавьте "+_vm._s(_vm.isMainPerson ? "утверждающего" : "первого согласующего"))])])]):_c('tbody',_vm._l((_vm.resultData),function(event){return _c('tr',{key:event.id,ref:event.id,refInFor:true},_vm._l((_vm.headers),function(header){return _c('td',{key:("" + (event.id) + (header.value)),staticClass:"document-table__td pl-3 pr-3 pt-2 pb-2",style:({ width: header.width ? ((header.width) + "px") : '' }),attrs:{"data-test-id":"tableCell"}},[(header.value === 'fullName')?_c('v-menu',{attrs:{"top":"","open-on-hover":"","offset-y":"","fixed":"","z-index":200,"close-delay":100,"disabled":!event.userInformationCommissionDTO},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:{ 'blue--text text-decoration-underline': event.userInformationCommissionDTO }},'div',attrs,false),on),[_vm._v(" "+_vm._s(event[header.value])+" ")])]}}],null,true)},[(event.userInformationCommissionDTO)?_c('div',{staticClass:"pa-4 signature"},[_c('div',{staticClass:"d-flex flex-row flex-wrap signature"},_vm._l((_vm.commissionMemberListFields),function(field){return _c('div',{key:field.value,class:("d-flex flex-column mr-2 mb-2 " + (field.className))},[_c('CardModalInfoElement',{attrs:{"title":field.title,"tooltip":field.tooltip,"info":event.userInformationCommissionDTO[field.value]}})],1)}),0)]):_vm._e()]):(header.value === 'status')?_c('div',[_c('v-tooltip',{attrs:{"fixed":"","z-index":"200","top":"","disabled":event[header.value] !== _vm.ApproveStatuses.APPROVED || !event.date},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"approve-table__status",attrs:{"width":"16","contain":"","src":_vm.statusIcon(event[header.value])}},on))]}}],null,true)},[_c('div',[_vm._v(_vm._s(_vm.signDate(event.date)))])])],1):(header.value === 'additionalSlot' && !_vm.isHideEditButtons)?_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete', event)}}},[_c('v-icon',{attrs:{"color":"#333333"}},[_vm._v("mdi-delete-outline")])],1)],1):(header.value === 'comment')?_c('div',{staticClass:"approve-table__comment"},[_vm._v(_vm._s(event[header.value]))]):(header.value === 'files')?_c('TableFileList',{attrs:{"files":event.files}}):_c('span',[_vm._v(_vm._s(event[header.value]))])],1)}),0)}),0)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }