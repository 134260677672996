export const commissionMemberListFields = [
  {
    value: "email",
    title: "Электронная почта",
    tooltip: "Электронная почта",
    className: "signature__element_title",
  },
  {
    value: "department",
    title: "Подразделение",
    tooltip: "Подразделение",
    className: "signature__element_title",
  },
  {
    value: "phone",
    title: "Телефон",
    tooltip: "Телефон",
    className: "signature__element_title",
  },
  {
    value: "position",
    title: "Должность",
    tooltip: "Должность",
    className: "signature__element_title",
  },
  {
    value: "messenger",
    title: "Мессенджер",
    tooltip: "Мессенджер",
    className: "signature__element_title",
  },
];
